.react-datepicker__header{
  background-color: #2875C2;
  color: white;
 
}


.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border: 1px solid  #2875C2;
  border-radius: 50%;
}

.react-datepicker__day-name{
   color:  white;
  border-radius: 50%;
}

.react-datepicker__current-month{
  color:  white;
 border-radius: 50%;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color:  #2875C2;
}



